const Boxes = {
  box1: function () {
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfobox =false;
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 12, 8, -4],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
        brd1.suspendUpdate();
        //title
        var resize1 = function () {
        brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
        brd1.fullUpdate();
        };
        window.addEventListener("resize", resize1);
      //
brd1.suspendUpdate();
brd1.options.layer['image'] =14;
brd1.create('text', [-0, 10.5, '<b> Types of Triangles <b>'],{anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}, fixed:true});
brd1.create('text', [-0, 9.5, '(Based on Interior Angles)'],{anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}, fixed:true});

//Variables
var lineVt = brd1.create('segment', [[0,1], [0,10]], {visible: false});
var lineHr = brd1.create('segment', [[0,0], [5,0]], {visible: false});
var pEq1 = brd1.create('point', [-4,0],{fixed: true, name:'A (Fixed)', size:3,face:'square', strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {offset: [0,-15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pEq2 = brd1.create('glider', [4, 0, lineHr],{name:'B (Drag me!)', size:2, face:'circle', strokeColor:'black', snapToGrid:true,label: {offset: [0,-15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pEq3 = brd1.create('glider', [0, 3, lineVt],{visible: true, name:'O (Drag me!)', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [0,15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Sides of the triangle
var eqAB = brd1.create('segment', [pEq1, pEq2], {strokeColor:'blue', strokeWidth:1, fixed:true});
var eqAC = brd1.create('segment', [pEq1, pEq3], {strokeColor:'blue', strokeWidth:1, fixed:true});
var eqCB = brd1.create('segment', [pEq2, pEq3], {strokeColor:'blue', strokeWidth:1, fixed:true});
brd1.create('polygon', [pEq1, pEq2, pEq3],{highlight:false, fixed:true, highlight:false, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});

//Angles
var angEq1 = brd1.create('angle', [pEq2, pEq1, pEq3],{name: ()=>(JXG.Math.Geometry.rad(pEq2, pEq1, pEq3)*180/Math.PI).toFixed(0) + '°', radius:1, strokeColor:'blue', fillColor:'blue', label: {offset: [10, 0] , color: 'black', fontSize:function(){return 14*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angEq2 = brd1.create('angle', [pEq1, pEq3, pEq2],{name:()=>(JXG.Math.Geometry.rad(pEq1, pEq3, pEq2)*180/Math.PI).toFixed(0) + '°', radius:1, strokeColor:'red', fillColor:'red',visible:true, label: {offset: [-5, 0] ,color: 'black', fontSize:function(){return 14*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angEq3 = brd1.create('angle', [pEq3, pEq2, pEq1],{name:()=>(JXG.Math.Geometry.rad(pEq3, pEq2, pEq1)*180/Math.PI).toFixed(0) + '°', radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{offset: [-10, -10] ,color: 'black', fontSize:function(){return 14*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//pIsos3.on('down', function(){i=0; j=0; return;});
//pIsos2.on('down', function(){i=0; j=0;  return;});
//pIsos1.on('down', function(){i=0; j=0;  return;});

//Texts

var isosceles = brd1.create('text', [0, -2, 'Triangle OAB is an Acute Triangle '],{display:'internal', anchorX:'middle', visible:()=>angEq2.Value()<Math.PI/2, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
var equilateral = brd1.create('text', [0.01, -2, 'Triangle OAB is an Obtuse Triangle '],{display:'internal',anchorX:'middle', visible:()=>angEq2.Value()>Math.PI/2, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
var Scalene = brd1.create('text', [0.01, -2, 'Triangle OAB is a Right Triangle '],{display:'internal',anchorX:'middle', visible:()=>angEq2.Value()==Math.PI/2, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
brd1.unsuspendUpdate();
},
box2: function () {
  var brd2 = JXG.JSXGraph.initBoard('jxgbox3',{boundingbox: [-10, 10, 10, -10],
  keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
//
  brd2.suspendUpdate();
//
  brd2.options.layer['image'] =14;
  brd2.create('text', [0, 9, '<b> Types of Triangles <b>'],{anchorX:'middle',highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd2.canvasWidth/500.)}},);
  brd2.create('text', [0, 7.5, '(Based on the Lengths of the Sides)'],{anchorX:'middle',highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd2.canvasWidth/500.)}},);
//
  var lineIs = brd2.create('segment', [[-7,1], [-7,10]], {visible: false});
  var pIsos3 = brd2.create('glider', [-7, 4, lineIs],{fixed: false,visible: true, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [-3,20], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pIsos1 = brd2.create('point', [function(){return pIsos3.X()-pIsos3.Y()/2},0],{fixed: false, name:'A', size:0, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {offset: [-15,10], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pIsos2 = brd2.create('point', [function(){return pIsos3.X()+pIsos3.Y()/2},0],{fixed: true, name:'B', size:0, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//
  var lineEq = brd2.create('segment', [[0,1], [0,10]], {visible: false});
  var pEq1 = brd2.create('point', [-3,0],{fixed: true, name:'A', size:0, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {offset: [-15,10], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pEq2 = brd2.create('point', [3, 0],{fixed: true, name:'B', size:0, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pEq3 = brd2.create('glider', [0, 2, lineEq],{visible: true, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [-3,20], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//
  var lineScalene = brd2.create('segment', [[10,7], [5,3]], {visible: false});
  var pScalene1 = brd2.create('point', [6,0],{fixed: true, name:'A', size:0, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {offset: [-15, 10], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pScalene2 = brd2.create('point', [9, 0],{fixed: true, name:'B', size:0, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pScalene3 = brd2.create('glider', [5 , 3, lineScalene],{visible: true, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [-3,20], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//
  //Sides of the triangle
  var isosAB = brd2.create('segment', [pIsos1, pIsos2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var isosAC = brd2.create('segment', [pIsos1, pIsos3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var isosCB = brd2.create('segment', [pIsos2, pIsos3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd2.create('polygon', [pIsos1, pIsos2, pIsos3],{highlight:false,fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});
//
  var eqAB = brd2.create('segment', [pEq1, pEq2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var eqAC = brd2.create('segment', [pEq1, pEq3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var eqCB = brd2.create('segment', [pEq2, pEq3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd2.create('polygon', [pEq1, pEq2, pEq3],{highlight:false,fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});
//
  var ScaleneAB = brd2.create('segment', [pScalene1, pScalene2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var ScaleneAC = brd2.create('segment', [pScalene1, pScalene3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var ScaleneCB = brd2.create('segment', [pScalene2, pScalene3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd2.create('polygon', [pScalene1, pScalene2, pScalene3],{highlight:false,fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});
  //Angles
  var angIsos1 = brd2.create('angle', [pIsos2, pIsos1, pIsos3],{highlight:false,name: '60°', radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [0,0], fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angIsos2 = brd2.create('angle', [pIsos1, pIsos3, pIsos2],{highlight:false,name: '60°', radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [-10,0], fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angIsos3 = brd2.create('angle', [pIsos3, pIsos2, pIsos1],{highlight:false,name: '60°', radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [-15,0], fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  //var angg = brd2.create('angle', [[50, 0], pIsos1, pIsos2],{radius:1, strokeColor:'green', fillColor:'green',visible:false});

  var angEq1 = brd2.create('angle', [pEq2, pEq1, pEq3],{highlight:false,name: function(){return (Math.atan(Math.abs(pEq3.Y())/pEq2.X())*180/Math.PI).toFixed(0) + '°'}, radius:1, strokeColor:'blue', fillColor:'blue', label: {offset: [10, 0] , color: 'blue', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angEq2 = brd2.create('angle', [pEq1, pEq3, pEq2],{highlight:false,name: function(){return (180-2*Math.atan(Math.abs(pEq3.Y())/pEq2.X())*180/Math.PI).toFixed(0) + '°'}, radius:1, strokeColor:'orange', fillColor:'orange',visible:true, label: {offset: [-5, 0] ,color: 'orange', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angEq3 = brd2.create('angle', [pEq3, pEq2, pEq1],{highlight:false,name: function(){return (Math.atan(Math.abs(pEq3.Y())/pEq2.X())*180/Math.PI).toFixed(0) + '°'}, radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{offset: [-10, -10] ,color: 'blue', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angScalene1 = brd2.create('angle', [pScalene2, pScalene1, pScalene3],{highlight:false, visible: false, name: '', radius:1, strokeColor:'yellow', fillColor:'yellow', label: {color: 'yellow', fontSize:function(){return 0*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd2.create('angle', [pScalene2, pScalene1, pScalene3],{highlight:false, name: function(){ return (180*angScalene1.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'yellow', fillColor:'yellow', label: {offset: [-15, -30] ,color: 'orange', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angScalene2 = brd2.create('angle', [pScalene1, pScalene3, pScalene2],{highlight:false, visible: false, name: '', radius:1, strokeColor:'purple', fillColor:'purple',visible:true, label: {color: 'purple', fontSize:function(){return 0*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd2.create('angle', [pScalene1, pScalene3, pScalene2],{highlight:false,name: function(){ return (180*angScalene2.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'purple', fillColor:'purple',visible:true, label: {offset: [0, -15],color: 'purple', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angScalene3 = brd2.create('angle', [pScalene3, pScalene2, pScalene1],{visible: false, name: '', radius:1, strokeColor:'green', fillColor:'green', visible:true, label:{color: 'green', fontSize:function(){return 0*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd2.create('angle', [pScalene3, pScalene2, pScalene1],{highlight:false, name: function(){ return (180*angScalene3.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'green', fillColor:'green', visible:true, label:{ color: 'green', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  //pIsos3.on('down', function(){i=0; j=0; return;});
  //pIsos2.on('down', function(){i=0; j=0;  return;});
  //pIsos1.on('down', function(){i=0; j=0;  return;});
  //Texts
  var isosceles = brd2.create('text', [-1, -2, 'Isosceles '],{visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var equilateral = brd2.create('text', [-8.2, -2, 'Equilateral '],{visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var Scalene = brd2.create('text', [6, -2, 'Scalene '],{visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  brd2.unsuspendUpdate();
},
box3: function () {
  var brd3 = JXG.JSXGraph.initBoard('jxgbox2',{boundingbox: [-10, 10, 10, -10],
      keepaspectratio: true, axis:false, ticks:{visible:false},
      grid:true, showCopyright:false, showNavigation:false,
      pan:{enabled:false}, zoom:{enabled:false}});

  brd3.suspendUpdate();

  brd3.options.layer['image'] =14;
  brd3.create('text', [0, 9, '<b> Types of Triangles <b>'],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd3.canvasWidth/500.)}},);
  brd3.create('text', [0 , 7.5, 'Based on Interior Angles'],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd3.canvasWidth/500.)}},);

  //Order
  //brd3.options.layer['imcrane'] =1;
  //brd3.options.layer['seesaw'] =2;
  //Variables
  var lineAc = brd3.create('segment', [[-6,0], [-6,1]], {visible: false});
  var lineOb = brd3.create('segment', [[-4,-1], [0,-1]], {visible: false});
  var lineAc2 = brd3.create('segment', [[1,-1], [5,-1]], {visible: false});

  var pAcute3 = brd3.create('glider', [-6, 4, lineAc],{fixed: false,visible: true, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [-3,20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pAcute1 = brd3.create('point', [-9, -1],{fixed: false, name:'A', size:0, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {offset: [-15,10], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pAcute2 = brd3.create('glider', [-4, -1 , lineOb],{fixed: false, name:'B', size:2, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var lineObtuse = brd3.create('segment', [[1,2], [1,10]], {visible: false});
  var pObtuse1 = brd3.create('point', [-1,-1],{fixed: true, name:'A', size:0, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {offset: [-15,10], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pObtuse2 = brd3.create('glider', [3,-1, lineAc2],{name:'B', size:2, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pObtuse3 = brd3.create('glider', [1, 4, lineObtuse],{visible: true, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [-3,20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var lineRight = brd3.create('segment', [[6,1], [6, 10]], {visible: false});
  var pRight1 = brd3.create('point', [6,-1],{fixed: true, name:'A', size:0, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {offset: [-15, 10], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pRight2 = brd3.create('point', [9, -1],{fixed: true, name:'B', size:0, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var pRight3 = brd3.create('glider', [6 , 4, lineRight],{visible: true, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [-3,20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


  //Sides of the triangle
  var AcuteAB = brd3.create('segment', [pAcute1, pAcute2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var AcuteAC = brd3.create('segment', [pAcute1, pAcute3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var AcuteCB = brd3.create('segment', [pAcute2, pAcute3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd3.create('polygon', [pAcute1, pAcute2, pAcute3],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});

  var ObtuseAB = brd3.create('segment', [pObtuse1, pObtuse2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var ObtuseAC = brd3.create('segment', [pObtuse1, pObtuse3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var ObtuseCB = brd3.create('segment', [pObtuse2, pObtuse3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd3.create('polygon', [pObtuse1, pObtuse2, pObtuse3],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});

  var rightAB = brd3.create('segment', [pRight1, pRight2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var rightAC = brd3.create('segment', [pRight1, pRight3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var rightCB = brd3.create('segment', [pRight2, pRight3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var tri = brd3.create('polygon', [pRight1, pRight2, pRight3],{highlight:false, fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});

  tri.setLabel('Right Triangle')
  tri.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd3.canvasHeight/800}});
  tri.on('over', function () {this.label.setAttribute({visible:true});});
  tri.on('out', function () {this.label.setAttribute({visible:false});});
  //Angles
  var angObt1 = brd3.create('angle', [pAcute2, pAcute1, pAcute3],{visible: false, name: '', radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [0,0], fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pAcute2, pAcute1, pAcute3],{name: function(){ return (180*angObt1.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [0,0], fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angObt2 = brd3.create('angle', [pAcute1, pAcute3, pAcute2],{visible: false, name: '', radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [-10,0], fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pAcute1, pAcute3, pAcute2],{name: function(){ return (180*angObt2.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'purple', fillColor:'purple', label: {color: 'purple', offset: [-10,0], fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angObt3 = brd3.create('angle', [pAcute3, pAcute2, pAcute1],{visible: false, name: '', radius:1, strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [-15,0], fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pAcute3, pAcute2, pAcute1],{name: function(){ return (180*angObt3.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'green', fillColor:'green', label: {color: 'green', offset: [-15,0], fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  //var angg = brd3.create('angle', [[50, 0], pAcute1, pAcute2],{radius:1, strokeColor:'green', fillColor:'green',visible:false});
  var angAc1 = brd3.create('angle', [pObtuse2, pObtuse1, pObtuse3],{visible: false, name: '', radius:1, strokeColor:'blue', fillColor:'blue', label: {offset: [10, 0] , color: 'blue', fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pObtuse2, pObtuse1, pObtuse3],{name: function(){ return (180*angAc1.Value()/Math.PI).toFixed(0)  + '°'},  radius:1, strokeColor:'grey', fillColor:'grey', label: {offset: [0, 0] , color: 'grey', fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angAc2 = brd3.create('angle', [pObtuse1, pObtuse3, pObtuse2],{visible: false, name: '', radius:1, strokeColor:'orange', fillColor:'orange',visible:true, label: {offset: [-5, 0] ,color: 'orange', fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pObtuse1, pObtuse3, pObtuse2],{name: function(){ return (180*angAc2.Value()/Math.PI).toFixed(0)  + '°'},  radius:1, strokeColor:'orange', fillColor:'orange',visible:true, label: {offset: [-10, -10] , color: 'orange', fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angAc3 = brd3.create('angle', [pObtuse3, pObtuse2, pObtuse1],{visible: false, name: '', radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{offset: [-10, -10] ,color: 'blue', fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pObtuse3, pObtuse2, pObtuse1],{name: function(){ return (180*angAc3.Value()/Math.PI).toFixed(0)  + '°'},  radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{offset: [-10, -10] ,color: 'blue', fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angRight1 = brd3.create('angle', [pRight2, pRight1, pRight3],{visible: false, name: '', radius:1, strokeColor:'yellow', fillColor:'yellow', label: {color: 'yellow', fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pRight2, pRight1, pRight3],{name: function(){ return (180*angRight1.Value()/Math.PI).toFixed(0)  + '°'}, radius:1, strokeColor:'yellow', fillColor:'yellow', label: {offset: [-15, -25] ,color: 'orange', fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angRight2 = brd3.create('angle', [pRight1, pRight3, pRight2],{visible: false, name: '', radius:1, strokeColor:'purple', fillColor:'purple',visible:true, label: {color: 'purple', fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  //brd3.create('angle', [pRight1, pRight3, pRight2],{name:' ', radius:1, strokeColor:'purple', fillColor:'purple',visible:true, label: {offset: [0, -15],color: 'purple', fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angRight3 = brd3.create('angle', [pRight3, pRight2, pRight1],{visible: false, name: '', radius:1, strokeColor:'green', fillColor:'green', visible:true, label:{color: 'green', fontSize:function(){return 0*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd3.create('angle', [pRight3, pRight2, pRight1],{name: '&theta;', radius:1, strokeColor:'green', fillColor:'green', visible:true, label:{ color: 'green', fontSize:function(){return 14*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var Acuteceles = brd3.create('text', [0.5, -2, 'Acute '],{highlight:false, visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd3.canvasWidth/500.)}});
  var Obtuseuilateral = brd3.create('text', [-7, -2, 'Obtuse '],{highlight:false,visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd3.canvasWidth/500.)}});
  var right = brd3.create('text', [5.8, -2, 'Right Triangle '],{highlight:false, visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd3.canvasWidth/500.)}});

  brd3.unsuspendUpdate();

}
}
export default Boxes;
