<template>
  <div>
    <h3 ref="ca">
      Types of Triangles
    </h3>
    <p>
      Triangles can be classified in either of two ways: (a) based on internal angles, or (b) based on lengths of its sides.
    </p>
    <h3 ref="sa">
      Classification based on Internal Angles
    </h3>
    <p>
      Triangles can be classified in three types based on its internal angles.
    </p>
    <p>
      <ul class="a">
        <li>
          <h5> Acute Triangle </h5>
          A triangle is said to be an acute triangle if all the three internal angles are acute ( \(< 90^o\)).
        </li>
        <li>
          <h5>Obtuse Triangle</h5>
          A triangle is said to be an obtuse triangle if one of its internal angle is an obtuse angle (\(> 90^o\)).
        </li>
        <li>
          <h5>Right Triangle</h5>
          A triangle is said to be a right triangle if one of its internal angle is a right angle (\( = 90^o\)).
        </li>
      </ul>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h3 ref="sa">
      Classification based on Sides
    </h3>
    <p>
      A triangle can be classified in three types depending on whether the three
      sides of the triangle are of the same length, or two sides are of the same length, or all are of different lengths.
    </p>
    <p>
      <ul class="a">
        <li>
          <h5> Scalene Triangle </h5>
          A triangle that has all of its three sides of different lengths is known as a scalene triangle.
        </li>
        <li>
          <h5> Isosceles Triangle</h5>
          A triangle that has two of its sides of the same length is known as isosceles triangle.
        </li>
        <li>
          <h5> Equilateral Triangle</h5>
          A triangle that has all of its sides of the same length is known as equilateral triangle.
        </li>
      </ul>
    </p>
    <v-layout justify-center>
      <div id="jxgbox3" class="edliy-box-about" />
    </v-layout>
    </v-responsive>
    <h3 ref="playgraph">
      MagicGraph &vert; Types of Triangles
    </h3>
    <p>
      This MagicGraph offers a visually interactive approach to understanding different types of triangles.
    </p>
    <!--    <h5> To Get Started: </h5>
    <p>
    You are given a triangle formed by points O, A and B.
    The vertices O, A, and B of the triangle can be dragged and moved around to change
    the type (i.e., acute, obtuse or right) and size of the triangle.
    You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices are dragged around.
    </p>
<h5> To Explore:</h5>
    <p>
 Drag points O, A and B of the triangle to change the type (i.e., acute, obtuse or right) and size of the triangle OAB.
 Then, tap on the two scissors to cut out the angles &alpha; and &beta;.
 No matter what type or size of the triangle you choose, the three interior
 angles &alpha;, &beta; and &gamma; always form a half circle when stacked adjacent to each other.
 </p> -->
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Types of Triangles';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Classification based on angles', img:'/assets/number-1.svg', action: () => this.goto('ca')},
      {title: 'Classification based on sides', img:'/assets/number-2.svg', action: () => this.goto('sa')},
      {title: 'Special Triangles', img:'/assets/number-3.svg', action: () => this.goto('ra')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
    Boxes.box3();
  }
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
